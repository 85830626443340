import type { MetaFunction } from "@remix-run/node";
import { Link, Outlet } from "@remix-run/react";

export const meta: MetaFunction = () => {
    return [
        { title: "IZONE - IELTS Chiến lược" },
        {
            name: "description",
            content: "IZONE - IELTS Chiến lược",
        },
    ];
};

export default function Index() {
    return (
        <>
            <div
                style={{
                    fontFamily: "system-ui, sans-serif",
                    lineHeight: "1.8",
                }}
                className="print:hidden"
            >
                <nav className="border-b">
                    <div className="container flex h-16 flex-row items-center">
                        <Link to="/">
                            {" "}
                            <img
                                width="120"
                                src="/logo.png"
                                alt="IZONE - IELTS Chiến lược"
                            />
                        </Link>
                    </div>
                </nav>
            </div>
            <Outlet />
        </>
    );
}
